/* import bootstrap to set changes */
/* make the customizations */
$primary: #4F2582;
$secondary: #EAEAEA;
$offcanvas-horizontal-width: 300px !important;

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

@font-face {
	font-family:"feeling_passionate";
	src: url(static/fonts/feeling_passionate_subset.otf);
}

@font-face {
	font-family:"gotham";
	src: url(static/fonts/GothamBook.woff);
	font-weight: normal;
}

@font-face {
	font-family:"gotham";
	src: url(static/fonts/GothamLight.woff);
	font-weight: lighter;
}

@font-face {
	font-family:"gotham";
	src: url(static/fonts/GothamBold.woff);
	font-style: bold;
	font-weight: bold;
}

$headings-font-family: "gotham", sans-serif; //"petrona", serif;
$font-family-sans-serif: "gotham", sans-serif;

.lead-lg {
	@extend .lead;
	font-size: $lead-font-size * 2 !important;
}

h1 {
	font-weight: bold !important;
}

nav {

	@include media-breakpoint-up(sm) {
	}
	@include media-breakpoint-up(md) {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		align-items: center !important;
		display: flex !important;
	}
	@include media-breakpoint-up(lg) {
	}
	@include media-breakpoint-up(xl) {
	}
	@include media-breakpoint-up(xxl) {
	}
}

nav .navbar-nav a {
	color: white;
	&:hover {
		color: #FFC107;
	}
	&.active:hover {
		color: #FFC107;
	}
	
	@include media-breakpoint-up(xl) {
		font-size: x-large;
	}
}

.navbar-nav {
	.nav-item {
		@include media-breakpoint-up(md) {
			display: flex !important;
			align-items: center !important;
			a {
				&:hover {
					color: #FFC107;
				}
			}
			&:hover {
				background: black !important;
			}
			height: 100% !important;
		}
	}

	.dropdown-menu {
		@include media-breakpoint-up(md) {
			border-radius: 0 !important;
			border: 0 !important;
			margin: 0 !important;
			padding: 0 !important;
		}
	}

	.dropdown-item {
		background: white !important;
		color: black !important;
		&.active {
			background: white !important;
			color: black !important;
		}

		@include media-breakpoint-up(md) {
			background: black !important;
			color: white !important;
			&:hover {
				color: #FFC107 !important;
			}
			&.active {
				background: black !important;
				color: white !important;
				&:hover {
					color: #FFC107 !important;
				}
			}
		}
	}

}

.dropdown-toggle::after {
	display: none !important;
}

@import "../node_modules/bootstrap/scss/bootstrap";

.navbar-brand {
	font-family: "petrona", serif;
	@include media-breakpoint-up(xl) {
		font-size: xx-large;
	}
}