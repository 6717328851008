@import "custom";

.scroller-margin {
	scroll-margin-top: 40px;
}
/*
:target {
	scroll-margin-top: 3vh;

	@include media-breakpoint-down(sm) {
		scroll-margin-top: 20vh;
	}
}
*/
html {
	@include media-breakpoint-up(sm) {
	}
	@include media-breakpoint-up(md) {
	}
	@include media-breakpoint-up(lg) {
	}
	@include media-breakpoint-up(xl) {
	}
	@include media-breakpoint-up(xxl) {
	}
}

.primary-bg {
	background-color: $primary;
	color: white;
}

.secondary-bg {
	background-color: $secondary;
}

.grey-bg {
	background-color: #4A4A4A;
	color: white;
}

.highlight-bg {
	background: #EBAA2040;
}

.gradient-bg {
	background: $primary;
	background: linear-gradient(0deg, $primary 75%, #005f73 100%);
	color: white;
}

.konnecting-kellogg-bg {
	background-image: url('static/images/kk-bg.png');
	background-size: auto 100%;

	object-fit: cover;
	@include media-breakpoint-up(md) {
		background-size: 100% 100%;
	}
	background-repeat: no-repeat;
	color: white;
}

.talk-to-us-bg {
	width: 100%;
	background-image: url('static/images/talk-to-us-bg.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	color: white;
}

.img-bg {
	min-width: 100%;
	height: auto;
	background: $secondary;
	background-size: 100% auto;
	background-repeat: no-repeat;
	color: white;
}

.dei-bg {
	@extend .img-bg;
	background-image: url('static/images/dei-xs.jpeg');
	@include media-breakpoint-up(md) {
		background-image: url('static/images/dei.jpeg');
	}
}

.pillars-bg {
	@extend .img-bg;
	background-image: url('static/images/pillars-bg-xs.jpg');
	@include media-breakpoint-up(md) {
		background-image: url('static/images/pillars-bg.jpg');
	}
}

.initiatives-body {
	@include media-breakpoint-up(sm) {
		width: 90%;
	}
	@include media-breakpoint-up(md) {
	}
	@include media-breakpoint-up(lg) {
	}
	@include media-breakpoint-up(xl) {
	}
	@include media-breakpoint-up(xxl) {
		width: 50%;
	}
}

.pillar-body {
	padding-left: 2.5rem !important;
	padding-right: 2.5rem !important;

	@include media-breakpoint-up(sm) {
	}
	@include media-breakpoint-up(md) {
		padding-left: 0rem !important;
	}
	@include media-breakpoint-up(lg) {
		padding-right: 5rem !important;
	}
	@include media-breakpoint-up(xl) {
		padding-right: 8rem !important;
	}
	@include media-breakpoint-up(xxl) {
		padding-right: 12rem !important;
	}
}

.pillar-square {
	padding: 2rem;
	@include media-breakpoint-up(sm) {
	}
	@include media-breakpoint-up(md) {
		padding: 0;
		width: 95%;
		p {
			font-size: .93rem;
		}
		h3 {
			font-size: 1.5rem;
		}
	}
	@include media-breakpoint-up(lg) {
		width: 90%;
		p {
			font-size: 1rem;
		}
	}
	@include media-breakpoint-up(xl) {
		width: 80%;
		p {
			font-size: 1.25rem;
		}
		h3 {
			font-size: 2.5rem;
		}
	}
	@include media-breakpoint-up(xxl) {
		width: 70%;

		p {
			font-size: 1.5rem;
		}
	}
}

.dei-body {
	@extend .pillar-body;
	overflow-wrap: break-word;
}

.banner-img {
	width: 100%;
	height: auto;
	visibility: hidden;
}

.banner-overlay {
	background-color: rgba(0, 0, 0, 0.80);
	
	@include media-breakpoint-up(sm) {
		width: 90%;
	}
	@include media-breakpoint-up(md) {
		width: 80%;
	}
	@include media-breakpoint-up(lg) {
		width: 60%;
	}
	@include media-breakpoint-up(xl) {
		width: 50%;
	}
	@include media-breakpoint-up(xxl) {
		width: 40%;
	}
}

// overlay elements need to be encompassed by an element with position=relative
.overlay {
	bottom: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.75)
}
.text-overlay-wrapper {
	position: absolute;
	left: 50%;
	top: 50%;

	.text-overlay {
		color: white;
		z-index: 1;
		transform: translate(-50%, -50%);
	}
}

.banner-text {
	@include media-breakpoint-up(lg) {
		width: 50%;
		margin-left: auto;
	}

	@media (min-width: md) {
		width: 100%;
	}
}

.darken-hover:hover{
	filter: brightness(50%);
}

.darken {
	filter: brightness(25%);
}

.navbar-header {
	font-weight: bold;
	display: flex !important;
	align-items: center !important;
	&:hover {
		color: white !important;
	}

	@include media-breakpoint-up(sm) {
	}
	@include media-breakpoint-up(md) {
		font-size: 1.4em !important;
	}
	@include media-breakpoint-up(lg) {
		font-size: 2.4em !important;
	}
	@include media-breakpoint-up(xl) {
	}
	@include media-breakpoint-up(xxl) {
		padding: 0;
		font-size: 3.65em !important;
	}
}


.navbar-item-padding {
	@include media-breakpoint-up(md) {
		padding-left: .75rem !important;
		padding-right: .75rem !important;
	}
}

.navbar-hover {
	@include media-breakpoint-up(md) {
		height: 100%;
		display: flex !important;
		align-items: center !important;
		&:hover {
			background-color: black;
		}
	}
}

.pillar-container {
	padding: 2.25rem;
	div h3 {
		font-size: 6vw
	}
	div p {
		font-size: 3.25vw
	}

	@include media-breakpoint-up(sm) {
		padding: 4rem;
	}
	@include media-breakpoint-up(md) {
		padding: 2rem;
		div h3 {
			font-size: 2.5vw
		}
		div p {
			font-size: 1.25vw
		}
	}

	@include media-breakpoint-up(lg) {
		padding: 3rem;
	}
	@include media-breakpoint-up(xl) {
		padding: 4rem;
	}
	@include media-breakpoint-up(xxl) {
		padding: 5rem;
	}
}

.table-of-contents {
	a.active {
		border-left-color: $primary;
		border-left-style: solid;
		//transition: border .5s ease-in-out
	}
	padding-left: .5rem !important;
	padding-right: .5rem !important;
	padding-bottom: 2rem !important;

	@include media-breakpoint-up(sm) {
	}
	@include media-breakpoint-up(md) {
		padding-bottom: 3rem !important;
		padding-top: 5rem !important;
	}

	@include media-breakpoint-up(lg) {
		padding-top: 7.5rem !important;
	}
	@include media-breakpoint-up(xl) {
		padding-top: 10rem !important;
	}
	@include media-breakpoint-up(xxl) {
	}
}

.learn-more-btn {
	padding: .075rem .4rem .075rem .4rem;
	font-size: .6rem;
	border-radius: .15rem;

	@include media-breakpoint-up(sm) {
	}
	@include media-breakpoint-up(md) {
		padding: .25rem .5rem .25rem .5rem;
		font-size: .875rem;
		border-radius: .25rem;
	}

	@include media-breakpoint-up(lg) {

	}
	@include media-breakpoint-up(xl) {
		padding: .375rem .75rem .375rem .75rem;
		font-size: 1rem;
		border-radius: .375rem;
	}
	@include media-breakpoint-up(xxl) {
	}

}


.scene {
  //perspective: 100em;
}

.card-flip {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: opacity .5s, transform .5s;
  opacity: 1;
}

.card__face {
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
}

.is-flipped {
	.card__face--front {
		transition: opacity .5s;
		opacity: 0;
	}
	.card__face--back {
		transition: opacity .5s;
		opacity: 1;
	}
}

.card__face--front {
	transition: opacity .5s;
}


.card__face--back {
	transform: rotateY( 180deg );
	transition: opacity .5s;
	opacity: 0;
}

.card-flip.is-flipped {
  transform: rotateY(180deg);
  transition: opacity .5s, transform .5s;
}

.carousel-wrapper {
  height: 400px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
